import DashboardGrid from '@/components/layout/DashboardGrid';
import { pageMetadataAtom } from '@/components/PageMetadata';
import JiraLink from '@/features/development/JiraLink';
import WorkInProgress from '@/features/development/WorkInProgress';
import Module from '@/types/Module';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';

const FrontPage = () => {
    const [, setPageMetadata] = useAtom(pageMetadataAtom);
    useEffect(
        () =>
            setPageMetadata({
                title: 'Dashboard',
                module: Module.NONE,
            }),
        [setPageMetadata]
    );

    return (
        <DashboardGrid>
            <WorkInProgress title="Jira Issues">
                <ol className="ml-8 mt-2 list-decimal">
                    <li>
                        <JiraLink
                            issue={583}
                            description={'Client Portal - Booking Review'}
                            completed={false}
                        />
                    </li>
                </ol>
            </WorkInProgress>
        </DashboardGrid>
    );
};

export default FrontPage;
