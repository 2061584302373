import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

export enum ButtonSize {
    SMALL,
    MEDIUM,
    LARGE,
}

export enum ButtonAction {
    NONE,
    SUBMIT,
}

interface ButtonProps {
    title: string;
    href?: string;
    action?: ButtonAction;
    classNames?: string;
    disabled?: boolean;
    onClickHandler?: () => void;
    size?: ButtonSize;
}

const Button = ({
    title,
    href,
    action = ButtonAction.NONE,
    classNames,
    disabled,
    onClickHandler = () => {
        /**/
    },
    size = ButtonSize.MEDIUM,
}: ButtonProps) => {
    const buttonClassNames = (classNames?: string, disabled?: boolean): string => {
        const disabledImpl: boolean = disabled ?? false;
        const classes = clsx(
            'inline-flex items-center border border-transparent font-semibold shadow',
            disabledImpl
                ? 'pointer-events-none bg-gray-200 text-gray-400 hover:bg-gray-300'
                : 'bg-blue-600 text-white hover:bg-blue-700',
            size === ButtonSize.SMALL ? 'text-xs px-3 py-1 rounded' : '',
            size === ButtonSize.MEDIUM ? 'text-sm px-4 py-2 rounded-md' : '',
            size === ButtonSize.LARGE ? 'text-base px-6 py-2.5 rounded-md' : '',
            classNames
        );

        return overrideTailwindClasses(classes);
    };

    const buttonAction = action === ButtonAction.NONE ? 'button' : 'submit';

    if (href !== undefined) {
        return (
            <Link
                href={href}
                className={buttonClassNames(classNames, disabled)}
                onClick={onClickHandler}
            >
                {title}
            </Link>
        );
    }

    return (
        <button
            className={buttonClassNames(classNames, disabled)}
            onClick={onClickHandler}
            type={buttonAction}
        >
            {title}
        </button>
    );
};

export default Button;
