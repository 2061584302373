import Button, {
    ButtonAction,
    ButtonSize,
} from '@/components/app/button/Button';
import React from 'react';

interface ButtonLinkProps {
    title: string;
    href: string;
    classNames?: string;
    disabled?: boolean;
    size?: ButtonSize;
}

const ButtonLink = ({
    title,
    href,
    classNames,
    disabled,
    size,
}: ButtonLinkProps) => {
    return (
        <Button
            title={title}
            href={href}
            action={ButtonAction.NONE}
            classNames={classNames}
            disabled={disabled}
            size={size}
        />
    );
};

export default ButtonLink;
