import Link from 'next/link';
import React from 'react';

interface JiraIssueType {
    issue: number;
    description: string;
    completed: boolean;
}

const jiraURL = 'https://helixbiostructures.atlassian.net/browse/PA-';

const JiraLink = ({ issue, description, completed }: JiraIssueType) => {
    const link = (
        <span>
            <Link href={jiraURL + issue} className="font-bold underline" target="_blank">
                PA-{issue}
            </Link>
            {description !== '' && <span className="ml-2">{description}</span>}
        </span>
    );

    if (completed) {
        return <span className="line-through">{link}</span>;
    }

    return link;
};

export default JiraLink;
