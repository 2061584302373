import { GetValidChildren } from '@/utils/GetValidChildren';
import React from 'react';

interface DashboardGridProps {
    children?: React.ReactNode;
    columns?: number;
}

const DashboardGrid = (props: DashboardGridProps) => {
    const childCount = GetValidChildren(props.children).length;
    const columns = props.columns ?? 2;
    if (childCount <= 1 || columns == 1) {
        return <div className="grid gap-y-2">{props.children}</div>;
    }

    if (columns == 2) {
        return <div className="grid gap-2 md:grid-cols-2">{props.children}</div>;
    }

    if (columns == 3) {
        return <div className="grid gap-2 md:grid-cols-3">{props.children}</div>;
    }

    return <div className="grid gap-2 md:grid-cols-4">{props.children}</div>;
};

export default DashboardGrid;
