import Section from '@/components/layout/Section';
import React from 'react';

interface WorkInProgressProps {
    title: string;
    children?: React.ReactNode;
    classNames?: string;
}

const WorkInProgress = (props: WorkInProgressProps) => {
    return (
        <Section
            containerClassNames={props.classNames}
            title={'Work-In-Progress: ' + props.title}
        >
            {props.children}
        </Section>
    );
};

export default WorkInProgress;
