import { ButtonSize } from '@/components/app/button/Button';
import ButtonLink from '@/components/app/button/ButtonLink';
import clsx from 'clsx';
import React from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

interface AdminLink {
    title: string;
    href: string;
}

interface SectionProps {
    children?: React.ReactNode;
    containerClassNames?: string;
    titleClassNames?: string;
    title?: string;
    admin?: Array<AdminLink>;
    popup?: Array<React.ReactNode>;
}

const Section = ({
    children,
    containerClassNames,
    title,
    titleClassNames,
    admin = [],
    popup = [],
}: SectionProps) => {
    return (
        <div
            className={overrideTailwindClasses(
                clsx(
                    'relative rounded bg-white px-4 pb-4 pt-2 shadow',
                    title !== undefined ? '' : 'pt-4',
                    containerClassNames
                )
            )}
        >
            {title !== undefined && (
                <h2
                    className={overrideTailwindClasses(
                        clsx('p-2 pb-4 text-center text-xl font-bold', titleClassNames)
                    )}
                >
                    {title}
                </h2>
            )}
            {(admin.length > 0 || popup.length > 0) && (
                <div className="absolute right-4 top-4 flex gap-2">
                    {admin.length > 0 &&
                        admin.map((link) => {
                            return (
                                <ButtonLink
                                    key={link.href}
                                    title={link.title}
                                    href={link.href}
                                    size={ButtonSize.SMALL}
                                    classNames="bg-red-600 hover:bg-red-700"
                                />
                            );
                        })}
                    {popup.length > 0 &&
                        popup.map((popupDialog) => {
                            return popupDialog;
                        })}
                </div>
            )}
            {children}
        </div>
    );
};

export default Section;
